export enum supportedLanguages {
  en = "en",
  de = "de",
  es = "es",
  frCa = "fr_CA",
  frFr = "fr_FR",
  nl = "nl",
  pt = "pt",
}

export const display = {
  [supportedLanguages.en]: {
    appHeader: "ChatGPC",
    appSubHeader: "Your Personal AI Assistant",
    login: "Log in",
    sidebarHeader: "ChatGPC",
    generalChatBotPrompt: `Hello, I'm a chatbot assistant. Ask me a question...`,
    disclosure:
      "Disclosure Notice! Do not input personal information into this tool. All usage of ChatGPC will be monitored and logged. Employees are expected to follow GPC policies and procedures as related to usage of ChatGPC. Always verify the accuracy and appropriateness of any outputs from ChatGPC.",
    truncationWarning:
      'ChatGPC\'s response has been cut off because it was too long. You can type "Continue" to see the rest of the response.',
    guidanceDisclaimer:
      "By clicking “Accept”, I attest that I have read the GPC Employee Generative AI Guidance document and will abide by the policies and procedures set in place by GPC. I understand that failure to follow these policies and procedures may result in disciplinary actions up to and including termination.",
    accept: "Accept",
    decline: "Decline",
  },
  [supportedLanguages.de]: {
    guidanceDisclaimer:
      "Durch Klicken auf „Akzeptieren“ bestätige ich, dass ich das Dokument „GPC Employee Generative AI Guidance“ gelesen habe und mich an die von GPC festgelegten Richtlinien und Verfahren halten werde. Ich verstehe, dass die Nichteinhaltung dieser Richtlinien und Verfahren Disziplinarmaßnahmen bis hin zur Kündigung nach sich ziehen kann.",
    accept: "Akzeptieren",
    decline: "Ablehnen",
  },
  [supportedLanguages.es]: {
    guidanceDisclaimer:
      'Al hacer clic en "Aceptar", declaro que he leído el documento de Orientación sobre IA Generativa para Empleados de GPC y que cumpliré con las políticas y procedimientos establecidos por GPC. Entiendo que el incumplimiento de estas políticas y procedimientos puede resultar en medidas disciplinarias, incluyendo el despido.',
    accept: "Aceptar",
    decline: "Rechazar",
  },
  [supportedLanguages.frCa]: {
    guidanceDisclaimer:
      "En cliquant sur « Accepter », j’atteste avoir lu le document intitulé Guide sur l’IA Générative pour les Employés de GPC et je m’engage à respecter les politiques et procédures établies par GPC. Je comprends que le non-respect de ces politiques et procédures peut entraîner des mesures disciplinaires pouvant aller jusqu’au licenciement.",
    accept: "Accepter",
    decline: "Refuser",
  },
  [supportedLanguages.frFr]: {
    guidanceDisclaimer:
      "En cliquant sur « Accepter », j’atteste avoir lu le document intitulé « Directives sur l’IA Générative pour les Employés de GPC » et m’engage à respecter les politiques et procédures mises en place par GPC. Je comprends que le non-respect de ces politiques et procédures peut entraîner des mesures disciplinaires pouvant aller jusqu’au licenciement.",
    accept: "Accepter",
    decline: "Refuser",
  },
  [supportedLanguages.nl]: {
    guidanceDisclaimer:
      "Door op 'Accepteren' te klikken, verklaar ik dat ik het GPC Employee Generative AI Guidance-document heb gelezen en mij zal houden aan de door GPC vastgestelde beleidslijnen en procedures. Ik begrijp dat het niet naleven van deze beleidslijnen en procedures kan leiden tot disciplinaire maatregelen, tot en met beëindiging van het dienstverband",
    accept: "Accepteren",
    decline: "Afwijzen",
  },
  [supportedLanguages.pt]: {
    guidanceDisclaimer:
      "Ao clicar em “Aceitar”, atesto que li o documento de Orientação sobre IA Generativa para Funcionários da GPC e que cumprirei as políticas e procedimentos estabelecidos pela GPC. Compreendo que o não cumprimento dessas políticas e procedimentos pode resultar em medidas disciplinares, incluindo e até mesmo a rescisão do contrato.",
    accept: "Aceitar",
    decline: "Recusar",
  },
} as const;

// export default display;
