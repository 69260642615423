import { PropsWithChildren } from "react";
import UserInfoContextWrapper from "../contexts/UserInfoContext";
import QueryClientWrapperWithErrorHandling from "./QueryClientWrapperWithErrorHandling";

const WrapperProvider = ({ children }: PropsWithChildren) => {
  return (
    <UserInfoContextWrapper>
      <QueryClientWrapperWithErrorHandling>
        {children}
      </QueryClientWrapperWithErrorHandling>
    </UserInfoContextWrapper>
  );
};

export default WrapperProvider;
