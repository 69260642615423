import { useCallback } from "react";
import { useSetUserInfoContext } from "../contexts/UserInfoContext";

const useInvalidateAccessToken = () => {
  const setUserInfo = useSetUserInfoContext()!;

  return useCallback(() => {
    setUserInfo((state) =>
      state?.accessToken ? { ...state, accessToken: "" } : null,
    );
  }, [setUserInfo]);
};

export default useInvalidateAccessToken;
