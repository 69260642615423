import { useUserInfoContext } from "../../contexts/UserInfoContext";
import { FETCH_USER_AGENT } from "../../config";

const useGetDefaultRequestOptions = (): RequestInit => {
  const userInfo = useUserInfoContext();

  return {
    headers: {
      "Content-Type": "application/json",
      ...(userInfo?.accessToken
        ? { Authorization: `Bearer ${userInfo.accessToken}` }
        : {}),
      "User-Agent": FETCH_USER_AGENT,
    },
    mode: "cors" as RequestMode,
  };
};

export default useGetDefaultRequestOptions;
