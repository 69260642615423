import { PingOneResponse, UserInfo } from "../types";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import {
  REFRESH_TOKEN_COOKIE_NAME,
  REFRESH_TOKEN_COOKIE_DEFAULT_EXPIRATION_DELAY_MS,
} from "../config/cookies";
import { Dispatch, SetStateAction } from "react";
import { FETCH_USER_AGENT } from "../config";

export class AuthService {
  static async getAccessToken(code: string): Promise<UserInfo> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "User-Agent": FETCH_USER_AGENT,
      },
      mode: "cors" as RequestMode,
    };

    const apiResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v2/token?code=${code}&redirect_uri=${process.env.REACT_APP_BASE_URL}`,
      requestOptions,
    );

    const response: PingOneResponse = await apiResponse.json();

    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      expiresAt: response.expires_in * 1000 + Date.now(),
    };
  }

  static async refreshToken(): Promise<UserInfo> {
    const refreshToken = AuthService.getRefreshTokenCookie();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
        "User-Agent": FETCH_USER_AGENT,
      },
      mode: "cors" as RequestMode,
    };

    const apiResponse = await fetch(
      process.env.REACT_APP_BACKEND_URL + `/api/v2/refresh`,
      requestOptions,
    );

    const response: PingOneResponse = await apiResponse.json();

    AuthService.setRefreshTokenCookie(response.refresh_token);

    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      expiresAt: response.expires_in * 1000 + Date.now(),
    };
  }

  static async getValidTokenFromInfo(
    userInfo: UserInfo,
    setUserInfo: Dispatch<SetStateAction<UserInfo | null>>,
  ) {
    if (userInfo.expiresAt > Date.now()) {
      return userInfo.accessToken;
    }

    try {
      const refreshResult = await this.refreshToken();
      setUserInfo(refreshResult);
      return refreshResult.accessToken;
    } catch (e: any) {
      console.error("Unable to refresh the token", e);
      setUserInfo(null);
      return null;
    }
  }

  static setRefreshTokenCookie(refresh_token: string): void {
    Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refresh_token, {
      expires: new Date(
        Date.now() + REFRESH_TOKEN_COOKIE_DEFAULT_EXPIRATION_DELAY_MS,
      ),
    });
  }

  static unsetRefreshTokenCookie(): void {
    Cookies.remove(REFRESH_TOKEN_COOKIE_NAME);
  }

  static getRefreshTokenCookie(): string | undefined {
    return Cookies.get(REFRESH_TOKEN_COOKIE_NAME);
  }

  // TODO: Use for determining access token cookie TTL
  static _getTokenExpirationDate(token: string): Date | undefined {
    try {
      const { exp } = jwtDecode(token);
      if (exp) {
        return new Date(exp * 1000);
      }
    } catch (error) {
      console.error("Failed to decode JWT:", error);
    }
  }
}
