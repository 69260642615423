import { useQuery } from "@tanstack/react-query";
import { Conversations } from "../../types";
import getChatConversations, {
  getChatConversationsQueryKey,
} from "../../queryFunctions/getChatConversations";
import useGetDefaultRequestOptions from "./useGetDefaultRequestOptions";

const useChatHistory = () => {
  const defaultRequestOptions = useGetDefaultRequestOptions();

  const query = useQuery<Conversations, Error>({
    queryKey: getChatConversationsQueryKey(),
    queryFn: getChatConversations(defaultRequestOptions),
  });

  return query;
};

export default useChatHistory;
