import HttpError, { UNAUTHORIZED_ERROR_MESSAGE } from "../utils/HttpError";

const throwErrorOnUnauthorized = (response: Response) => {
  // Note: for some cases error 500 might mean unauthorized.
  //        For now, the behavior for 500 error should be the same as for unauthorized.
  if (
    response.status === 401 ||
    response.status === 403 ||
    response.status === 500
  ) {
    throw new HttpError(UNAUTHORIZED_ERROR_MESSAGE, response.status);
  }
};

export default throwErrorOnUnauthorized;
