import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PropsWithChildren, useEffect } from "react";
import useInvalidateAccessToken from "../hooks/useInvalidateAccessToken";
import HttpError, { UNAUTHORIZED_ERROR_MESSAGE } from "../utils/HttpError";

const queryClient = new QueryClient();

function guardUnauthorizedErrorStatusCode(error: HttpError) {
  return error?.message === UNAUTHORIZED_ERROR_MESSAGE;
}

const QueryClientWrapperWithErrorHandling = ({
  children,
}: PropsWithChildren) => {
  const invalidateAccessToken = useInvalidateAccessToken();

  // Query Cache subscription
  useEffect(() => {
    return queryClient.getQueryCache().subscribe((event) => {
      if (event?.type === "updated" && event.query.state.error) {
        const { error } = event.query.state;
        if (guardUnauthorizedErrorStatusCode(error as HttpError)) {
          invalidateAccessToken();
        }
      }
    });
  }, [invalidateAccessToken]);

  // Mutation Cache subscription
  useEffect(() => {
    return queryClient.getMutationCache().subscribe((event) => {
      if (event?.type === "updated" && event.mutation.state.error) {
        const { error } = event.mutation.state;
        if (guardUnauthorizedErrorStatusCode(error as HttpError)) {
          invalidateAccessToken();
        }
      }
    });
  }, [invalidateAccessToken]);

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryClientWrapperWithErrorHandling;
