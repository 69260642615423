import { useEffect, useState } from "react";
import { supportedLanguages } from "../utils/displayValues";

const useGenerativeAIGuidance = (language: supportedLanguages) => {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    fetch(`/docs/guidance/guidance-v1-${language}.md`)
      .then((r) => r.text())
      .then(setText);
  }, [language]);

  return text;
};

export default useGenerativeAIGuidance;
