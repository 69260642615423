import React, { Dispatch, SetStateAction } from "react";
import "./termsAndConditionsStyles.css";

import ChatGPCLogomark from "../../ChatGPCLogomark";
import GuidanceInfoContent from "../Info/GuidanceInfoContent";
import { useSetUserInfoContext } from "../../../contexts/UserInfoContext";
import useAcceptTermsAndConditions from "../../../hooks/api/useAcceptTermsAndConditions";
import { LanguageList } from "../Info/LanguageList";

import { display, supportedLanguages } from "../../../utils/displayValues";

type TermsAndConditionsProps = {
  setTermsAccepted: Dispatch<SetStateAction<boolean>>;
  language: supportedLanguages;
  setLanguage: Dispatch<SetStateAction<supportedLanguages>>;
};

const TermsAndConditions = ({
  setTermsAccepted,
  language,
  setLanguage,
}: TermsAndConditionsProps) => {
  const setUserInfo = useSetUserInfoContext();

  const { acceptTerms } = useAcceptTermsAndConditions();
  const handleAccept = async () => {
    setTermsAccepted(true);
    await acceptTerms();
  };

  const handleDecline = async () => {
    setUserInfo(null);
  };

  // TODO: if (isLoadingTerms) return <div>Loading</div>
  return (
    <div>
      <div className="terms-header-bar">
        <ChatGPCLogomark className="gpc-logo-terms" />
      </div>
      <div className="terms-content">
        <LanguageList language={language} setLanguage={setLanguage} />
        <div className="terms-buttons-wrapper">
          <div className="terms-body">
            <GuidanceInfoContent language={language} />
          </div>
          <div className="red-text">{display[language].guidanceDisclaimer}</div>
          <div className="button-group">
            <button onClick={handleDecline} className="decline-button">
              {display[language].decline}
            </button>
            <button
              data-testid="accept-terms"
              className="accept-button"
              onClick={handleAccept}
            >
              {display[language].accept}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
