import React from "react";
import config from "../../../config/pingone";
import { display } from "../../../utils/displayValues";
import "./LandingPageStyles.css";

import { Button, Heading } from "@bolt/components";
import FooterLinks from "../../FooterLinks";

const LandingPage = () => {
  const handleSignIn = () => {
    const state = generateRandomValue();
    const nonce = generateRandomValue();
    // Store state and nonce parameters into the session, so we can retrieve them after
    // user will be redirected back with access token or code (since react state is cleared in this case)
    sessionStorage.setItem("state", state);
    sessionStorage.setItem("nonce", nonce);

    const authUrl =
      `${config.pingUrl}?` +
      `client_id=${config.clientId}&` +
      `redirect_uri=${config.redirectUri}&` +
      `response_type=${config.responseType ? config.responseType : "token id_token"}` +
      (config.prompt ? `&prompt=${config.prompt}` : "") +
      (config.scope ? `&scope=${config.scope}` : "") +
      (config.maxAge ? `&max_age=${config.maxAge}` : "") +
      (state ? `&state=${state}` : "") +
      (nonce ? `&nonce=${nonce}` : "");

    window.location.replace(authUrl);
  };

  const generateRandomValue = () => {
    let crypto = window.crypto;
    let D = new Uint32Array(2);
    crypto.getRandomValues(D);
    return D[0].toString(36);
  };

  return (
    <div className="landing-container">
      <div className="leftPane">
        <img src="./GPC.png" className="app-logo" alt="logo" />
      </div>
      <div className="rightPane">
        <div className="right-pane-content">
          <Heading level="h1" variant="lg">
            {display["en"].appHeader}
          </Heading>
          <Heading level="h2" variant="lg">
            {display["en"].appSubHeader}
          </Heading>
          <div>
            <Button intent="accent" size="xl" onClick={handleSignIn}>
              {display["en"].login}
            </Button>
          </div>
        </div>
        <FooterLinks />
      </div>
    </div>
  );
};

export default LandingPage;
