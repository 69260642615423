import React from "react";
import ReactMarkdown from "react-markdown";

import "./GuidancePageContentStyles.css";
import useUserGuide from "../../../hooks/useUserGuide";
import { supportedLanguages } from "../../../utils/displayValues";

type UserGuideInfoContentProps = {
  language: supportedLanguages;
};

const UserGuideInfoContent = ({ language }: UserGuideInfoContentProps) => {
  const text = useUserGuide(language);

  return (
    <div>
      <div className="text-container">
        <ReactMarkdown>{text}</ReactMarkdown>
      </div>
    </div>
  );
};

export default UserGuideInfoContent;
