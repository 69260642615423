import React from "react";
import "./GuidancePageContentStyles.css";

const FaqInfoContent = () => (
  <div className="text-container faq">
    <h1>Frequently asked questions</h1>

    <h2>What are common use cases for ChatGPC?</h2>
    <p>
      ChatGPC can be used for many different tasks. These include generating
      product descriptions, summarizing long texts, and creating marketing
      content like ad copies or social media posts. It can also perform
      classification tasks and help with generating, analyzing and improving
      code. Additionally, ChatGPC has a wide range of other applications. You
      can try it out for various work-related tasks to discover what works best
      for your needs.
    </p>

    <h2>
      How was ChatGPC trained / What LLM (Large language model) is used in
      ChatGPC?
    </h2>
    <p>
      Today, ChatGPC is using the gpt-4o model developed by OpenAI, which is the
      same large language model behind ChatGPT. In the future, we will add the
      option for users to choose other supported models such as Google Gemini
      and Anthropic Claude.
    </p>

    <h2>Can ChatGPC create custom visuals and other images?</h2>
    <p>
      No, currently ChatGPC is not able to generate images or visuals. It is a
      text-to-text model. There may be exploration of image generation models in
      the future, but that is not available at this time.
    </p>

    <h2>
      Now that generative AI is being allowed at GPC, can I use other tools like
      Co-pilot or ChatGPT?
    </h2>
    <p>
      No, other tools like Co-pilot or ChatGPT are not allowed at GPC due to
      security and privacy concerns. The only approved tools are ChatGPC and
      CodeGPC (IDE plugin for ChatGPC).
    </p>

    <h2>Will my conversation train the model to my unique needs?</h2>
    <p>
      No, your conversations will not train the model to your unique needs. The
      model can remember the context of your current conversation, but it does
      not learn or adapt beyond that session.
    </p>

    <h2>Can I use ChatGPC for personal use?</h2>
    <p>
      No, you can only use ChatGPC for work-related tasks. Personal use is not
      permitted.
    </p>

    <h2>Can I trust ChatGPC?</h2>
    <p>
      While ChatGPC is a useful tool, it's important to know that it can make
      mistakes or provide inaccurate information. These tools may be prone to
      hallucinations, meaning they can provide completely fabricated
      information. You should always verify and double-check important
      information.
    </p>

    <h2>What is a prompt?</h2>
    <p>
      A prompt is an input or instruction given to a chatbot like ChatGPC to
      generate a response. It can be a question, a statement, or a specific task
      that guides the chatbot in producing a relevant and coherent output.
      Prompts help define the context and direction for the chatbot's response.
    </p>

    <h2>Is my conversation in ChatGPC private?</h2>
    <p>
      All usage of ChatGPC is audited via Guardrails by the GenAI Audit team.
      Employees are expected to follow GPC policies and procedures regarding its
      use.
    </p>

    <h2>What types of sensitive data can I use with ChatGPC?</h2>
    <p>
      Do not submit any kind of personal data, such as your social security
      number, credit card information, or physical address into ChatGPC.
    </p>

    <h2>Can I share my link to ChatGPC?</h2>
    <p>
      No, it is not currently possible to share your ChatGPC session with other
      users.
    </p>

    <h2>Is ChatGPC accessible on Mobile? Is there a Mobile App?</h2>
    <p>
      Yes, ChatGPC should be accessible from phones and tablets. However, please
      note that there is no dedicated app available and the mobile experience
      may not be optimal. For the best experience, we strongly recommend using
      it on a desktop.
    </p>

    <h2>What is the difference between ChatGPT and ChatGPC?</h2>
    <p>
      ChatGPC is an internal tool designed specifically for GPC employees with
      security features that make it safe for company-related tasks unlike
      ChatGPT. While ChatGPT currently has more features than ChatGPC, we are
      actively working on adding new features to ChatGPC to enhance its
      functionality over time. er time.
    </p>

    <h2>What are the tips and tricks on how to write an effective prompt?</h2>
    <p>
      For tips and tricks on how to write an effective prompt, please refer to
      our <a href="/user-guide">user guide</a>, specifically the "Best Prompting
      Practices" section. It provides detailed guidance to help you craft
      prompts that yield the best results.
    </p>

    <h2>Is ChatGPC reliable for answering questions about current events?</h2>
    <p>
      Unfortunately, ChatGPC is not reliable for answering questions about
      current events. It uses the GPT-4o model, which has a training cutoff date
      of October 2023. This means that any information about events occurring
      after this date may not be accurate or available.
    </p>
  </div>
);

export default FaqInfoContent;
