import { FETCH_USER_AGENT } from "../config";
import {
  ChatMessageAssistant,
  ChatRequest,
  ChatResponseSchema,
  ErrorType,
  RephraseResponseSchema,
  UserInfo,
} from "../types";
import { AuthService } from "./AuthService";
import { Dispatch, SetStateAction } from "react";
import { z } from "zod";

export class ChatGpcService {
  static abortController: AbortController | undefined;
  static ERRORS = {
    SESSION_EXPIRED:
      "We're sorry, your session has expired. Please log in again.",
    UNEXPECTED:
      "We're sorry, an unexpected error occurred while retrieving your answer. Please try resubmitting your question. If you keep getting this message, please contact us at chatgpc_support@genpt.com.",
    ABORTED:
      "The response generation has been stopped. You can enter a new question or resubmit a previous one to continue.",
    TOO_MANY_TOKENS:
      "We're sorry, but your message is too long. Please shorten it and try again.",
  };

  static async abortQuestion() {
    this.abortController?.abort();
  }

  static async apiRequest<T extends z.Schema>({
    path,
    method,
    payload,
    responseSchema,
    userInfo,
    setUserInfo,
  }: {
    path: string;
    method: "GET" | "POST";
    payload: Record<string, any>;
    responseSchema: T;
    userInfo: UserInfo;
    setUserInfo: Dispatch<SetStateAction<UserInfo | null>>;
  }): Promise<
    | { response: z.infer<T>; error?: never }
    | { response?: never; error: string; errorType: ErrorType }
  > {
    this.abortQuestion();
    this.abortController = new AbortController();

    const accessToken = await AuthService.getValidTokenFromInfo(
      userInfo,
      setUserInfo,
    );
    if (!accessToken) {
      return {
        error: this.ERRORS.SESSION_EXPIRED,
        errorType: ErrorType.SESSION_EXPIRED,
      };
    }

    const uri: string = process.env.REACT_APP_BACKEND_URL + path;

    try {
      const response = await fetch(uri, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "User-Agent": FETCH_USER_AGENT,
        },
        body: JSON.stringify(payload),
        signal: this.abortController.signal,
        mode: "cors" as RequestMode,
      });
      if (response.status === 401) {
        setUserInfo(null);
        return {
          error: this.ERRORS.SESSION_EXPIRED,
          errorType: ErrorType.SESSION_EXPIRED,
        };
      }
      if (response.status === 400) {
        return {
          error: this.ERRORS.TOO_MANY_TOKENS,
          errorType: ErrorType.TOO_MANY_TOKENS,
        };
      }
      if (response.status > 299) {
        return {
          error: this.ERRORS.UNEXPECTED,
          errorType: ErrorType.OTHER,
        };
      }

      return { response: responseSchema.parse(await response.json()) };
    } catch (error: any) {
      return {
        error:
          error.name === "AbortError"
            ? this.ERRORS.ABORTED
            : this.ERRORS.UNEXPECTED,
        errorType: ErrorType.OTHER,
      };
    }
  }

  static async askQuestion({
    chatRequest,
    userInfo,
    setUserInfo,
  }: {
    chatRequest: ChatRequest;
    userInfo: UserInfo;
    setUserInfo: Dispatch<SetStateAction<UserInfo | null>>;
  }): Promise<ChatMessageAssistant> {
    const response = await this.apiRequest({
      method: "POST",
      path: "/api/v2/chat",
      payload: chatRequest,
      responseSchema: ChatResponseSchema,
      setUserInfo,
      userInfo,
    });

    return response.error === undefined
      ? {
          role: "assistant",
          content: response.response.answer,
          id: response.response.question_id,
          creation_timestamp: response.response.question_timestamp,
          isTruncated: response.response.is_truncated,
          error: false,
        }
      : {
          content: response.error,
          role: "assistant",
          error: true,
          errorType: response.errorType,
          isTruncated: false,
        };
  }

  static async rephraseQuestion({
    question,
    userInfo,
    setUserInfo,
  }: {
    question: string;
    userInfo: UserInfo;
    setUserInfo: Dispatch<SetStateAction<UserInfo | null>>;
  }): Promise<string | null> {
    const response = await this.apiRequest({
      method: "POST",
      path: "/api/v2/chat/rephrase",
      payload: { prompt: question },
      responseSchema: RephraseResponseSchema,
      setUserInfo,
      userInfo,
    });

    return response.error === undefined
      ? response.response.rephrased_prompt
      : null;
  }
}
