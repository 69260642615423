import React from "react";
import ReactMarkdown from "react-markdown";

import "./GuidancePageContentStyles.css";
import useGenerativeAIGuidance from "../../../hooks/useGenerativeAIGuidance";
import { supportedLanguages } from "../../../utils/displayValues";

type GuidanceInfoContentProps = {
  language: supportedLanguages;
};

const GuidanceInfoContent = ({ language }: GuidanceInfoContentProps) => {
  const text = useGenerativeAIGuidance(language);
  return (
    <div>
      <div className="text-container">
        <ReactMarkdown>{text}</ReactMarkdown>
      </div>
    </div>
  );
};

export default GuidanceInfoContent;
