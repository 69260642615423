import { Conversations } from "../types";
import groupConversationsByTimeSpans from "../utils/groupConversationsByTimeSpans";
import { TIME_SPAN_GROUPS } from "../utils/timespans.consts";
import ChatHistorySection from "./ChatHistorySection";

type ChatHistoryListProps = {
  conversations: Conversations;
  activeConversationId: string;
  requestChatContentFromHistory: (chatID: string) => void;
};

const ChatHistoryList = ({
  conversations,
  activeConversationId,
  requestChatContentFromHistory,
}: ChatHistoryListProps) => {
  const conversationsGrouped = groupConversationsByTimeSpans(conversations);

  return (
    <div>
      {TIME_SPAN_GROUPS.map((timeSpan) => timeSpan.label).map(
        (timespan) =>
          conversationsGrouped[timespan]?.length && (
            <ChatHistorySection
              activeConversationId={activeConversationId}
              key={timespan}
              requestChatContentFromHistory={requestChatContentFromHistory}
              conversations={conversationsGrouped[timespan]}
              timespan={timespan}
            />
          ),
      )}
    </div>
  );
};

export default ChatHistoryList;
