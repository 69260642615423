import { ChatMessage, ErrorType } from "../types";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import { MdOutlineFaceUnlock } from "react-icons/md";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  nightOwl,
  prism,
} from "react-syntax-highlighter/dist/cjs/styles/prism";
import { ChatTheme } from "../hooks/useTheme";
import CopyToClipboard from "./CopyToClipboard";
import "./ChatMessageComponentStyles.css";
import ChatErrorMessage from "./ChatErrorMessage";
import { Button } from "@bolt/components";
import ChatActions from "./ChatActions";
import { memo } from "react";
import MessageBot from "./MessageBot";
import { display } from "../utils/displayValues";


const ErrorButton = ({
  onClick,
  text,
}: {
  onClick: () => any;
  text: string;
}) => (
  <div className="chat-action-button-wrapper">
    <Button
      onClick={onClick}
      className="error-chat-button"
      size="lg"
      variant="solid"
      intent="primary"
    >
      {text}
    </Button>
  </div>
);

const getErrorButton = (
  errorType: ErrorType,
  handleRegenerateResponse: () => void,
  handleRedirectToRoot: () => void,
) => {
  switch (errorType) {
    case ErrorType.SESSION_EXPIRED: {
      return <ErrorButton onClick={handleRedirectToRoot} text="Log in" />;
    }
    case ErrorType.TOO_MANY_TOKENS: {
      return null;
    }
    default: {
      return (
        <ErrorButton
          onClick={handleRegenerateResponse}
          text="Regenerate your question"
        />
      );
    }
  }
};

type ChatMessageComponentProps = {
  message: ChatMessage;
  theme: ChatTheme;
  isLatest?: boolean;
  handleRegenerateResponse: () => void;
  handleRedirectToRoot: () => void;
};

const ChatMessageComponent = ({
  message,
  theme,
  isLatest = false,
  handleRegenerateResponse,
  handleRedirectToRoot,
}: ChatMessageComponentProps) => {
  if (message.error) {
    return (
      <>
        <ChatErrorMessage errorMessage={message.content} />
        {isLatest &&
          getErrorButton(
            message.errorType,
            handleRegenerateResponse,
            handleRedirectToRoot,
          )}
      </>
    );
  }

  if (message.role === "assistant") {
    return (
      <>
        <MessageBot>
          <div>
            <ReactMarkdown
              className="chat-markdown"
              // Note: taken from documentation:
              // https://github.com/remarkjs/react-markdown#use-custom-components-syntax-highlight
              remarkPlugins={[remarkGfm]}
              components={{
                code(props) {
                  const { children, className, node, ...rest } = props;
                  const match = /language-(\w+)/.exec(className || "");
                  return match ? (
                    <div className="code-container">
                      <SyntaxHighlighter
                        {...rest}
                        showLineNumbers
                        children={String(children).replace(/\n$/, "")}
                        language={match[1]}
                        style={theme === "dark" ? nightOwl : prism}
                      />
                      <CopyToClipboard
                        className="copy-code-button"
                        textToCopy={String(children)}
                      />
                    </div>
                  ) : (
                    <code {...rest} className={className}>
                      {children}
                    </code>
                  );
                },
              }}
            >
              {message.content}
            </ReactMarkdown>
            {message.isTruncated && (
              <div className="truncated-warning">
                <p>{display.en.truncationWarning}</p>
              </div>
            )}
          </div>
        </MessageBot>
        {isLatest && message.id && message.creation_timestamp && (
          <ChatActions
            textToCopy={message.content}
            questionId={message.id}
            questionTimestamp={message.creation_timestamp}
          />
        )}
      </>
    );
  }

  return (
    <div className="message user-message">
      <div>
        <MdOutlineFaceUnlock className="chat-img" size="100%" />
      </div>
      <p className="chat-markdown">{message.content}</p>
    </div>
  );
};

export default memo(ChatMessageComponent);
