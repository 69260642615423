import React, { useCallback, useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { TextareaAutosize } from "@mui/material";
import { Button, IconButton, Switch, Tooltip } from "@bolt/components";
import { ChatGpcService } from "../../../services/ChatGpcService";
import { ChatMessage } from "../../../types";
import "./ChatUiStyles.css";
import ChatMessageComponent from "../../ChatMessageComponent";
import MessageBot from "../../MessageBot";
import SkeletonTextBlock from "../../SkeletonTextBlock";
import ChatGPCLogo from "../../ChatGPCLogo";
import LogOutButton from "../../LogOutButton";
import useTheme from "../../../hooks/useTheme";
import DisclosureWarning from "../../DisclosureWarning";
import MobileSidebarToggleButton from "../../MobileSidebarToggleButton";
import ChatFooterDisclosure from "../../ChatFooterDisclosure";
import clsx from "clsx";
import {
  useSetUserInfoContext,
  useUserInfoContext,
} from "../../../contexts/UserInfoContext";
import { useUserQuestion } from "../../../hooks/useUserQuestion";
import ChatHistory from "../../ChatHistory";
import getChatConversationMessages, {
  getChatConversationsMessagesQueryKey,
} from "../../../queryFunctions/getChatConversationMessages";
import LoadingSection from "../../LoadingSection";
import { getChatConversationsQueryKey } from "../../../queryFunctions/getChatConversations";
import chatHistoryToChatHistoryDTO from "../../../utils/chatHistoryToChatHistoryDTO";
import BotWelcomeMessage from "../../BotWelcomeMessage";

function generateNewChatID() {
  return crypto.randomUUID();
}

const ChatUi = () => {
  const setUserInfo = useSetUserInfoContext();
  const userInfo = useUserInfoContext()!;
  const MAX_CHAR_LIMIT = 500000;
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] =
    useState(false);

  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);

  const [chatState, setChatState] = useState<
    "ready" | "responding" | "rephrasing"
  >("ready");
  const { userQuestion, setUserQuestion, clearQuestionContext, rephrase } =
    useUserQuestion();
  const [chatID, setChatID] = useState<string>(generateNewChatID());

  // Note: The name for this state is not perfect. Might be refactored
  const [isNewChat, setIsNewChat] = useState<boolean>(true);
  const msgEnd = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { theme, toggleTheme } = useTheme();
  const [expandedSidebarOnMobile, setExpandedSidebarOnMobile] =
    useState<boolean>(false);

  const queryFetchConversationMessages = useQuery({
    queryKey: getChatConversationsMessagesQueryKey(chatID),
    queryFn: getChatConversationMessages(userInfo.accessToken, chatID),
    enabled: !isNewChat,
  });
  const queryClient = useQueryClient();

  // Note: It updates state chatHistory once data are downloaded by Query
  useEffect(() => {
    if (!!queryFetchConversationMessages.data) {
      setChatHistory(queryFetchConversationMessages.data);
    }
  }, [queryFetchConversationMessages.data]);

  useEffect(() => {
    msgEnd?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [chatHistory]);

  useEffect(() => {
    if (chatState === "ready") focusTextInput();
  }, [chatState]);

  useEffect(() => {
    if (userQuestion.length > MAX_CHAR_LIMIT) {
      setIsCharacterLimitExceeded(true);
    } else {
      setIsCharacterLimitExceeded(false);
    }
  }, [userQuestion]);

  const focusTextInput = () => {
    if (!textAreaRef.current) return;
    const textArea = textAreaRef.current;
    textArea.focus();
    textArea.setSelectionRange(textArea.value.length, textArea.value.length);
  };

  const handleNewChat = async () => {
    await handleAbort();

    setTimeout(() => {
      setChatID(generateNewChatID());
      setIsNewChat(true);
      setChatHistory([]);
    }, 0);
  };

  const handleAbort = useCallback(async () => {
    await ChatGpcService.abortQuestion();
    clearQuestionContext();
    setChatState("ready");
  }, [clearQuestionContext, setChatState]);

  const requestChatContentFromHistory = useCallback(
    (requestChatID: string) => {
      handleAbort();
      setIsNewChat(false);
      setChatID(requestChatID);
    },
    [handleAbort, setIsNewChat, setChatID],
  );

  const toggleSidebar = useCallback(() => {
    setExpandedSidebarOnMobile((state) => !state);
  }, [setExpandedSidebarOnMobile]);

  const handleSend = async () => {
    clearQuestionContext();
    await sendMessage(userQuestion);

    queryClient.invalidateQueries({ queryKey: getChatConversationsQueryKey() });
  };

  const sendMessage = useCallback(
    async (message: string) => {
      setChatState("responding");
      setChatHistory((state) => [
        ...state,
        { content: message, role: "user", error: false },
      ]);

      const result: ChatMessage = await ChatGpcService.askQuestion({
        chatRequest: {
          chat_history: chatHistoryToChatHistoryDTO(chatHistory),
          question: message,
          chat_id: chatID,
        },
        userInfo,
        setUserInfo,
      });

      setChatHistory((state) => [...state, result]);
      setChatState("ready");
    },
    [setChatHistory, setChatState, userInfo, chatHistory, chatID, setUserInfo],
  );

  const handleRephrase = async () => {
    setChatState("rephrasing");
    const rephrased = await ChatGpcService.rephraseQuestion({
      question: userQuestion,
      userInfo,
      setUserInfo,
    });
    if (rephrased) rephrase.push(rephrased);
    setChatState("ready");
  };

  const handleRegenerateResponse = useCallback(() => {
    const userQuestion =
      chatHistory
        .slice()
        .reverse()
        .find((message: ChatMessage) => message.role === "user")?.content || "";

    if (userQuestion) {
      sendMessage(userQuestion);
    } else {
      console.error("The user question from chatHistory is empty!");
    }
  }, [sendMessage, chatHistory]);

  const onKeyDownTextarea = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (isCharacterLimitExceeded || userQuestion.length === 0) {
        e.preventDefault();
        return;
      }
      handleSend();
      e.preventDefault();
    }
  };

  const handleRedirectToRoot = useCallback(() => {
    setUserInfo(null);
  }, [setUserInfo]);

  return (
    <div className="theme-container" data-theme={theme}>
      <div
        className={clsx(
          "chat-screen-container",
          expandedSidebarOnMobile && "sidebar-expanded",
        )}
      >
        <div className="sidebar">
          <ChatGPCLogo className="chat-img" />
          <div className="sidebarUpper">
            <div className="sidebar-category">
              <Button
                size="lg"
                intent="primary"
                data-testid="clear-history-btn"
                onClick={handleNewChat}
                disabled={isNewChat && !chatHistory.length}
              >
                New chat
              </Button>
            </div>
          </div>
          <ChatHistory
            activeConversationId={chatID}
            requestChatContentFromHistory={requestChatContentFromHistory}
          />
          <div className="theme-switch-container">
            <Switch
              data-testid="dark-mode-toggle"
              onClick={toggleTheme}
              label="dark mode"
              size="md"
              checked={theme === "dark"}
            />
          </div>
        </div>
        <div className="main">
          <div className="chat-header">
            <MobileSidebarToggleButton onClick={toggleSidebar} />
            <DisclosureWarning />
            <LogOutButton />
          </div>
          {queryFetchConversationMessages.isLoading ? (
            <LoadingSection />
          ) : (
            <div className="chats">
              <BotWelcomeMessage />
              {chatHistory.map((message: ChatMessage, index: number) => (
                <ChatMessageComponent
                  key={index}
                  theme={theme}
                  message={message}
                  handleRegenerateResponse={handleRegenerateResponse}
                  handleRedirectToRoot={handleRedirectToRoot}
                  isLatest={index === chatHistory.length - 1}
                />
              ))}
              {chatState === "responding" && (
                <MessageBot>
                  <div style={{ width: "100%" }}>
                    <SkeletonTextBlock />
                  </div>
                </MessageBot>
              )}
              <div ref={msgEnd} />
            </div>
          )}
          <div
            className={`input-box-container ${
              isCharacterLimitExceeded ? "input-box-container-error" : ""
            }`}
            onClick={(e) => {
              if (e.target === e.currentTarget) focusTextInput();
            }}
          >
            <div
              className={clsx("chat-text-area", {
                "error-border": isCharacterLimitExceeded,
              })}
            >
              <TextareaAutosize
                ref={textAreaRef}
                data-testid="chat-input"
                aria-label="Ask chatbot"
                draggable={false}
                disabled={chatState !== "ready"}
                placeholder="Ask me a question"
                onKeyDown={onKeyDownTextarea}
                onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                  setUserQuestion(e.currentTarget.value)
                }
                value={userQuestion}
              />
            </div>
            <div className="chat-buttons">
              {isCharacterLimitExceeded && (
                <div className="character-limit-warning">
                  {userQuestion.length} / {MAX_CHAR_LIMIT} characters
                </div>
              )}
              {chatState === "rephrasing" && (
                <div className="rephrase-status">Rephrasing...</div>
              )}
              {chatState === "ready" && rephrase.canRevert && (
                <Button
                  className="rephrase-undo"
                  iconLeft="clock-rotate-left"
                  onClick={rephrase.revert}
                  intent="primary"
                  variant="ghost"
                  size="md"
                >
                  Undo rephrase
                </Button>
              )}
              {chatState === "ready" && (
                <Tooltip content="Rephrase my prompt (beta)" color="inverted">
                  <IconButton
                    ariaLabel="Rephrase my prompt (beta)"
                    onClick={handleRephrase}
                    className="rephrase-button"
                    data-testid="rephrase-button"
                    size="md"
                    iconName="sparkles"
                    variant="outline"
                    disabled={!userQuestion.length || isCharacterLimitExceeded}
                  />
                </Tooltip>
              )}
              {chatState !== "ready" && (
                <Tooltip content="Stop response" color="inverted">
                  <IconButton
                    ariaLabel="Stop response"
                    onClick={handleAbort}
                    className="abort-button"
                    data-testid="abort-button"
                    size="md"
                    iconName="xmark-large"
                    variant="outline"
                    // Note: This is commented because it doesn't work as expected at current version of BOLT package
                    // The override has been added at css file
                    // intent="destructive"
                  />
                </Tooltip>
              )}
              {chatState === "ready" && (
                <Tooltip content="Submit my prompt" color="inverted">
                  <IconButton
                    ariaLabel="Submit my prompt"
                    disabled={userQuestion === "" || isCharacterLimitExceeded}
                    className="send-button"
                    data-testid="send-button"
                    onClick={handleSend}
                    size="md"
                    iconName="chevron-right"
                    variant="outline"
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <ChatFooterDisclosure />
        </div>
      </div>
    </div>
  );
};

export default ChatUi;
