import { FeedbackThumbsType } from "../../types";
import useGetDefaultRequestOptions from "./useGetDefaultRequestOptions";

export type SendFeedbackType = {
  questionId: string;
  thumbs: FeedbackThumbsType;
  userFeedback: string | null;
  questionTimestamp: string;
};

const useFeedback = () => {
  const defaultRequestOptions = useGetDefaultRequestOptions();

  const sendFeedback = async ({
    questionId,
    thumbs,
    userFeedback,
    questionTimestamp,
  }: SendFeedbackType) => {
    const FEEDBACK_ENDPOINT: string =
      process.env.REACT_APP_BACKEND_URL + "/api/v2/feedback";

    const requestOptions = {
      ...defaultRequestOptions,
      method: "POST",
      body: JSON.stringify({
        question_id: questionId,
        question_timestamp: questionTimestamp,
        user_rating: thumbs === "up" ? 1 : -1,
        user_comment: userFeedback,
      }),
    };

    try {
      const res = await fetch(FEEDBACK_ENDPOINT, requestOptions);
      if (res.status > 299) {
        throw new Error("Failed to send feedback");
      }

      await res.json();
    } catch (err: any) {
      console.error(err.message);
    }
  };

  return { sendFeedback };
};

export default useFeedback;
