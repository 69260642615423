export const TIME_SPAN_GROUPS = [
  {
    label: "Last 24 hours",
    minusDays: 0,
  },
  {
    label: "Last 48 hours",
    minusDays: 1,
  },
  {
    label: "Last 30 days",
    minusDays: 2,
  },
  {
    label: "Older (up to 90 days)",
    minusDays: 30,
  },
];
