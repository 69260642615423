import { Conversations } from "../types";
import ChatHistoryItem from "./ChatHistoryItem";

import "./ChatHistorySectionStyles.css";

type ChatHistorySectionProps = {
  conversations: Conversations;
  timespan: string;
  requestChatContentFromHistory: (chatID: string) => void;
  activeConversationId: string;
};
const ChatHistorySection = ({
  activeConversationId,
  conversations,
  timespan,
  requestChatContentFromHistory,
}: ChatHistorySectionProps) => (
  <div className="chat-history-section">
    <h5>{timespan}</h5>
    {conversations.map((conversation, index) => (
      <ChatHistoryItem
        conversation={conversation}
        isActive={activeConversationId === conversation.id}
        requestChatContentFromHistory={requestChatContentFromHistory}
        key={index}
      />
    ))}
  </div>
);

export default ChatHistorySection;
