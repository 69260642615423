import { Conversations } from "../types";
import throwErrorOnUnauthorized from "./throwErrorOnUnauthorized";

export const getChatConversationsQueryKey = () => {
  return ["chatHistory"];
};

function getChatConversations(defaultRequestOptions: RequestInit) {
  return async (): Promise<Conversations> => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v2/chat/conversations`,
      defaultRequestOptions,
    );

    throwErrorOnUnauthorized(response);

    return (await response.json()).conversations;
  };
}

export default getChatConversations;
