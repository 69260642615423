import { useCallback } from "react";
import { FETCH_USER_AGENT } from "../../config";

const useTermsAndConditions = () => {
  const getTerms = useCallback(async (accessToken: string) => {
    const TERMS_ENDPOINT: string =
      process.env.REACT_APP_BACKEND_URL + "/api/v2/terms";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "User-Agent": FETCH_USER_AGENT,
      },
      mode: "cors" as RequestMode,
    };

    try {
      const response = await fetch(TERMS_ENDPOINT, requestOptions);

      if (response.ok) {
        const data = await response.json();

        return { response: data };
      } else {
        const error =
          response.status === 403
            ? "You must first complete the required Workday training for access.\n\n" +
              "ChatGPC access may take up to the next business day after successful training completion."
            : "An error occurred while authenticating";

        return { error };
      }
    } catch (err) {
      console.error(err);
      return { error: err };
    }
  }, []);

  return {
    getTerms,
  };
};

export default useTermsAndConditions;
