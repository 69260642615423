import ChatHistoryList from "./ChatHistoryList";

import "./ChatHistoryStyles.css";
import { memo } from "react";
import LoadingSection from "./LoadingSection";
import useChatHistory from "../hooks/api/useChatHistory";

type ChatHistoryProps = {
  requestChatContentFromHistory: (chatID: string) => void;
  activeConversationId: string;
};

const ChatHistory = ({
  requestChatContentFromHistory,
  activeConversationId,
}: ChatHistoryProps) => {
  const query = useChatHistory();

  return (
    <div
      className="chat-history-container"
      data-testid="chat-history-container"
    >
      {query.isPending && <LoadingSection />}
      {query.isSuccess && (
        <ChatHistoryList
          conversations={query.data}
          activeConversationId={activeConversationId}
          requestChatContentFromHistory={requestChatContentFromHistory}
        />
      )}
      {query.isError && <div>Failed to load history.</div>}
    </div>
  );
};

export default memo(ChatHistory);
