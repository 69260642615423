import "./LoadingSectionStyles.css";
import { Loading } from "@bolt/components";

const LoadingSection = () => (
  <div className="loading-section" role="progressbar">
    <Loading size="lg" />
  </div>
);

export default LoadingSection;
