import { useState } from "react";
import useGetDefaultRequestOptions from "./useGetDefaultRequestOptions";

const useAcceptTermsAndConditions = () => {
  const defaultRequestOptions = useGetDefaultRequestOptions();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const acceptTerms = async () => {
    const TERMS_ENDPOINT: string =
      process.env.REACT_APP_BACKEND_URL + "/api/v2/terms/accept";

    const requestOptions = {
      ...defaultRequestOptions,
      method: "POST",
    };

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(TERMS_ENDPOINT, requestOptions);
      const data = await response.json();
      console.log("Accepted terms response: ", data);
    } catch (err) {
      setError("Failed to accept terms");
      console.error("Failed accepting terms", err);
    } finally {
      setLoading(false);
    }
  };

  return {
    error,
    loading,
    acceptTerms,
  };
};

export default useAcceptTermsAndConditions;
