import throwErrorOnUnauthorized from "./throwErrorOnUnauthorized";

export const deleteChatConversationQueryKey = () => {
  return ["deleteChatHistory"];
};

const deleteChatConversation = (
  defaultRequestOptions: RequestInit,
  chatID: string,
) => {
  return async (): Promise<void> => {
    const requestOptions = {
      ...defaultRequestOptions,
      method: "DELETE",
    };

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v2/chat/conversations/${chatID}`,
      requestOptions,
    );

    throwErrorOnUnauthorized(response);

    return;
  };
};

export default deleteChatConversation;
